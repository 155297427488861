<template>
	<div class="list-tool-bar">
    <el-button @click="emit('onCreate')" type="primary">{{ t('table.create') }}</el-button>
    <el-button @click="emit('onDelete')" type="primary" plain>{{ t('form.delete') }}</el-button>
		<div class="search">
			<div class="search_item">
				<el-input
          v-model="searchData.search"
          @input.native="doSearch"
          :prefix-icon="Search"
					clearable
          :placeholder="t('form.search_placeholder')"
          class="search-input" />
			</div>
			<div class="search_item search_time">
				<div class="time_select">
					<el-select v-model="timeType">
						<el-option :label="t('table.creation_time')" value="1" />
						<el-option :label="t('table.login_time')" value="2" />
					</el-select>
				</div>
				<!-- 时间选择框 -->
				<custem-time ref="timeRef" @setTime="setTime" />
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref, inject } from 'vue'
import { Search } from '@element-plus/icons-vue'

const t = inject('t')
const timeType = ref('1')
const emit = defineEmits(['getList', 'onCreate', 'onDelete'])
const searchData = ref({
	filter_role_alias: 'SalesMan'
})
const setTime = (timeDate) => {
	if (timeDate.length) {
		if (timeType.value === '1') {
			searchData.value.filter_login_at = ''
			searchData.value.filter_created_at = timeDate[0]
		} else {
			searchData.value.filter_created_at = ''
			searchData.value.filter_login_at = timeDate[0]
		}
		searchData.value.filter_end_at = timeDate[1]
	}
	doSearch()
}

// 搜索事件
const doSearch = () => {
	for (let key in searchData.value) {
		if (!!!searchData.value[key]) {
			delete searchData.value[key]
		}
	}
	emit('getList', searchData.value)
}
</script>
